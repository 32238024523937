<template>
  <div class="market bgcolor">
      <Head :title="$t('home.sheng_market')" :show="true" :bg="true" />
      <div class="market-top"></div>
      <div class="market-main">
            <div class="market-main-add">
                <div class="one-banner" v-if="false">
                    <MySwipe class="banner" id="bone" :swiperoptions="swiperoptions"  >
                        <MySwipeItem v-for="(l,i) in banner" :key="i" >
                                <img class="b-img" v-lazy="$host+l" alt="">   
                                <p class="banner-count">{{i+1}} / {{banner.length}} </p>
                        </MySwipeItem>
                    </MySwipe>
                </div>
                <div class="market-box">             
                    <div class="market-list">
                        <div class="market-item" v-for="(l,i) in tickList" :key="i">
                            <!-- <div v-html="l.Introduction"></div> -->
                            <img class="item-img" alt="" v-lazy="$host+l.image">
                            <div class="item-box">
                                <h3>{{l.name}}</h3>
                                <p class="p-lot">{{$t('home.face_value')}}: ${{l.face_value }}</p>
                                <p class="p-value">{{$t('home.value')}}: {{l.price | number_format}} PV</p>
                                <p class="p-lot">{{$t('home.inventory')}}:{{l.inventory}}</p>
                                <div class="btnlist">
                                    <van-button @click="showDetail(l)" class="btn btn-detail">{{$t('good.detail')}}</van-button>
                                    <van-button @click="payaction(l)" class="btn btn-pay">{{$t('wallet.exchange')}}</van-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>

        <van-popup class="login-popup market-one" v-model="show" position="bottom"   duration="0.2" >
            <div class="login-popup-box">
                <div class="pop-top">
                    <p>{{$t('good.good')}}{{$t('good.detail')}}</p>
                    <van-icon name="cross" class="pop-close" @click="show=false" />
                </div>
                <div class="mask-one">
                    <h3>{{item.name}}</h3>
                    <div class="g-detail" v-html="item.Introduction"></div>
                    <template v-if="false">
                        <p>使用方法:</p>
                        <div>
                            微信扫码二维码获取{卡号和密码},复制粘贴至
                            {星巴克APP}点击“福利购”—“我的星礼卡”—右上角
                            “绑定会员星礼卡”输入卡号和密码即可成功充值。  
                        </div>
                    </template>
                </div>
                <!-- <van-button class="sure-btn" @click="sureChange">{{$t('login.sure')}}</van-button> -->
            </div>
        </van-popup>

        <van-popup class="login-popup market-one" v-model="payShow" position="bottom"   duration="0.2" >
            <div class="login-popup-box">
                <div class="pop-top">
                    <p>{{$t('wallet.exchange')}}</p>
                    <van-icon name="cross" class="pop-close" @click="payShow=false" />
                </div>
                <div class="mask-two">
                    <div class="payyue item">
                        <span>{{$t('common.yue')}}</span>
                        <span>{{balance | number_format }} PV</span>
                    </div>
                    <div class="paycount item">
                        <span>{{$t('auction.quantity')}}</span>
                        <van-stepper v-model="value"  @change="chaoguoMax"  :max="item.inventory"/>
                    </div>
                    <div class="payvalue item">
                        <span>{{$t('home.value')}}</span>
                        <span>{{value*item.price}} PV</span>
                    </div>
                </div>
                <van-button class="sure-btn" @click="sureChange">{{$t('login.sure')}}</van-button>
            </div>
        </van-popup>
        
  </div>
</template>

<script>
import {randomString,myinfo} from "@/common"
import {Toast,Notify} from "vant"
export default {
    // mixins:[myinfo],
    data(){
        return {
            value:1,
            show:false,
            payShow:false,
            item:{},
            banner:[],
            tickList:[],
            balance:0,
            swiperoptions:{
                observer:true,
                // loop:true,
                autoplay:{
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,  
                },
                resistanceRatio:0,
                spaceBetween : 30,
                slidesPerView :1.2,
                 on:{
                    slideChange: function(){
                        // that.current = this.activeIndex;
                    },
                },
            }
        }
    },
    methods:{
        chaoguoMax(){
            if(this.value>this.item.inventory){
                Toast(this.$t('home.tow_more_than'))
            }
        },
        showDetail(item){
            this.item = item;
            this.show = true;
        },
        sureChange(){
            this.$ajax.buyTicket({
                good_id:this.item.id,
                total:this.value,
                form_token:randomString()
            })
            .then(res=>{
                if(res.code==200){
                    Notify({ type: 'success', message: res.msg ,duration:1200});
                    this.payShow = false;
                    // 库存减少
                    this.tickList.forEach((item,idx)=>{
                        if(item.id==this.item.id){
                            item = {...item,inventory:item.inventory-this.value}
                        }
                        this.$set(this.tickList,idx,item);
                    })
                }
            })
        },
        payaction(item){
            var access_token = localStorage.getItem("access_token"); 
            if(access_token){
                this.$ajax.walletmy()
                .then(res=>{
                    if(res.code==200){
                        res.data.list.forEach(item=>{
                            if(item.current_id==1033){
                                this.balance = item.usable_balance
                            }
                        })
                        this.payShow = true;
                        this.item = item;
                        this.value = 1;
                    }
                })
            }else{
                this.$router.push({name:'signin'})
            }
            
        }
    },
    mounted(){
        this.$ajax.livingList()
        .then(res=>{
            if(res.code==200){
                var reg = /^banner/;
                for(var key in res.data.banner[0]){
                    if(reg.test(key)&&res.data.banner[0][key]){
                        this.banner.push(res.data.banner[0][key])
                    }
                }
                this.tickList = res.data.tick;
            }
        })
    }
}
</script>
